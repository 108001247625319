/* StakeholderIdentification.css */

/* Visually Hidden (for screen readers) */
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  white-space: nowrap;
}

.stakeholder-identification-page {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 20px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.stakeholder-identification-page.dark {
  background-color: #191a1a;
  color: #ffffff;
}

.stakeholder-identification-page.light {
  background-color: #ffffff;
  color: #333333;
}

/* Header */
.stakeholder-identification-header {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 20px;
}

.stakeholder-identification-title-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.stakeholder-identification-title-section {
  display: flex;
  align-items: center;
  gap: 12px;
}

.stakeholder-identification-title {
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 28px;
  font-weight: normal;
  margin: 0;
}

.stakeholder-identification-prompt-container {
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
  max-width: 300px;
}

.stakeholder-identification-prompt-container form {
  flex: 1;
  max-width: 100%;
}

.stakeholder-identification-query-input {
  width: 100%;
  max-width: 100%;
  font-size: 28px;
  background: none;
  border: none;
  outline: none;
  padding: 8px 0;
  transition: all 0.3s ease;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.stakeholder-identification-query-input.dark {
  color: #ffffff;
}

.stakeholder-identification-query-input.light {
  color: #333333;
}

.stakeholder-identification-query-input::placeholder {
  color: #888;
}

.stakeholder-identification-query-input:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(63, 189, 230, 0.5);
}

/* Submit button */
.stakeholder-identification-submit-button {
  background: rgb(63, 189, 230);
  border: none;
  font-size: 13px;
  cursor: pointer;
  padding: 8px;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  color: #1A1C1C;
  margin-left: 8px;
}

.stakeholder-identification-submit-button:hover {
  background-color: rgba(63, 189, 230, 0.8);
}

.stakeholder-identification-submit-button.dark {
  background: rgb(63, 189, 230);
  color: #1A1C1C;
}

.stakeholder-identification-submit-button.dark:hover {
  background-color: rgba(63, 189, 230, 0.8);
}

/* Buttons Row */
.stakeholder-identification-buttons-row {
  display: flex;
  align-items: center;
  gap: 12px;
}

.stakeholder-identification-controls {
  display: flex;
  align-items: center;
  gap: 8px;
}

.stakeholder-identification-button-with-tooltip {
  position: relative;
}

/* Dropdown buttons */
.stakeholder-identification-dropdown-button,
.stakeholder-identification-library-dropdown-button {
  padding: 6px 12px;
  border: none;
  border-radius: 4px;
  background: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 16px;
  transition: all 0.2s ease;
}

.stakeholder-identification-dropdown-button:hover,
.stakeholder-identification-library-dropdown-button:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.stakeholder-identification-dropdown-button.dark,
.stakeholder-identification-library-dropdown-button.dark {
  color: #fff;
}

.stakeholder-identification-dropdown-button.dark:hover,
.stakeholder-identification-library-dropdown-button.dark:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.stakeholder-identification-library-dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  min-width: 200px;
  max-height: 300px;
  overflow-y: auto;
  margin-top: 4px;
  display: none;
}

.stakeholder-identification-library-dropdown-menu.dark {
  background: #333;
  border-color: #555;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.stakeholder-identification-library-dropdown-menu.show {
  display: block;
}

.stakeholder-identification-dropdown-item {
  padding: 8px 12px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.stakeholder-identification-dropdown-item:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.stakeholder-identification-dropdown-item.dark:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

/* Tooltip */
.stakeholder-identification-tooltip-text {
  visibility: hidden;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  z-index: 1000;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.stakeholder-identification-button-with-tooltip:hover .stakeholder-identification-tooltip-text {
  visibility: visible;
  opacity: 1;
}

/* Underline */
.stakeholder-identification-underline {
  height: 1px;
  background-color: #ddd;
  margin: 20px 0;
}

.stakeholder-identification-page.dark .stakeholder-identification-underline {
  background-color: #444;
}

/* Main Content */
.stakeholder-identification-main-content {
  position: relative;
  min-height: 200px;
}

/* Error */
.stakeholder-identification-error {
  background-color: #ff5555;
  color: #ffffff;
  padding: 12px;
  border-radius: 4px;
  margin: 12px 0;
}

/* Results */
.stakeholder-identification-results {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  margin-top: 20px;
}

.stakeholder-identification-result-card {
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 16px;
  transition: all 0.3s ease;
}

.stakeholder-identification-result-card.dark {
  background-color: #2d2e2e;
  border-color: #444;
}

/* Result Header */
.stakeholder-identification-result-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.stakeholder-identification-result-content {
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 100%;
}

.stakeholder-identification-save-button-container {
  display: flex;
  justify-content: center;
  margin-top: auto;
  padding-top: 15px;
}

.stakeholder-identification-save-button {
  padding: 8px 24px;
  border-radius: 4px;
  border: none;
  background-color: rgb(63, 189, 230);
  color: black;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.2s ease;
}

.stakeholder-identification-save-button:hover:not(:disabled) {
  background-color: #006c75;
}

.stakeholder-identification-save-button.dark:hover:not(:disabled) {
  background-color: #006c75;
}

.stakeholder-identification-save-button.saved {
  background-color: #4caf50;
}

.stakeholder-identification-save-button.dark.saved {
  background-color: #4caf50;
}

.stakeholder-identification-save-button:disabled {
  opacity: 0.7;
  cursor: default;
}

/* Responsive Design */
@media (max-width: 768px) {
  .stakeholder-identification-title {
    font-size: 24px;
  }

  .stakeholder-identification-query-input {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .stakeholder-identification-controls {
    flex-direction: column;
    align-items: flex-start;
  }
}