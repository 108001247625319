/* TechScouting.css */

/* Base styles */
.tech-scouting-page {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 20px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* Header */
.tech-scouting-header {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 20px;
}

.tech-scouting-title-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  width: 100%;
  margin-bottom: 15px;
}

.tech-scouting-title-section {
  flex: 0 0 auto;
  margin-right: 10px;
}

.tech-scouting-title {
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 28px;
  font-weight: normal;
  margin: 0;
}

.tech-scouting-results-count {
  flex: 0 0 auto;
  font-size: 20px;
  font-weight: bold;
}

.tech-scouting-results-count.dark {
  color: #fff;
}

.tech-scouting-prompt-container {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-right: -20px; /* Changed from 170px to center the search input */
}

.tech-scouting-buttons-row {
  display: flex;
  align-items: center;
  gap: 10px;
  padding-left: 2px;
  justify-content: space-between;
}

.tech-scouting-controls {
  display: flex;
  align-items: center;
  gap: 10px;
}

/* Tech scouting buttons row */
.tech-scouting-buttons-row {
  display: flex;
  margin-top: -30px;
  position: relative;
  z-index: 5;
}

/* Submit button */
.tech-scouting-submit-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  padding: 4px 8px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  color: #0066cc;
  margin-left: 8px;
}

.tech-scouting-submit-button:hover {
  background-color: rgba(0, 102, 204, 0.1);
}

.tech-scouting-submit-button.dark {
  color: #3399ff;
}

.tech-scouting-submit-button.dark:hover {
  background-color: rgba(51, 153, 255, 0.1);
}

/* Dropdown buttons */
.tech-scouting-dropdown-button,
.tech-scouting-library-dropdown-button {
  padding: 6px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  transition: all 0.2s ease;
}

.tech-scouting-dropdown-button:hover,
.tech-scouting-library-dropdown-button:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.tech-scouting-dropdown-button.dark,
.tech-scouting-library-dropdown-button.dark {
  border-color: #555;
  color: #fff;
}

.tech-scouting-dropdown-button.dark:hover,
.tech-scouting-library-dropdown-button.dark:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

/* Dropdown menus */
.tech-scouting-dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  min-width: 150px;
  margin-top: 4px;
  display: none;
}

.tech-scouting-dropdown-menu.dark {
  background: #333;
  border-color: #555;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.tech-scouting-dropdown-menu.open {
  display: block;
}

.tech-scouting-dropdown-item {
  padding: 8px 12px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.tech-scouting-dropdown-item:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.tech-scouting-dropdown-item.dark:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

/* Position containers */
.tech-scouting-button-with-tooltip {
  position: relative;
}

.tech-scouting-library-dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  min-width: 200px;
  max-height: 300px;
  overflow-y: auto;
  margin-top: 4px;
  display: none;
}

.tech-scouting-library-dropdown-menu.dark {
  background: #333;
  border-color: #555;
}

.tech-scouting-library-dropdown-menu.open {
  display: block;
}

/* Tooltip */
.tech-scouting-tooltip-text {
  visibility: hidden;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  z-index: 1000;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.tech-scouting-button-with-tooltip:hover .tech-scouting-tooltip-text {
  visibility: visible;
  opacity: 1;
}

/* Main content */
.tech-scouting-main-content {
  margin-top: 30px;
}

/* Table styles */
.tech-scouting-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

.tech-scouting-table th,
.tech-scouting-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.tech-scouting-table th:nth-child(1),
.tech-scouting-table td:nth-child(1) {
  width: 15%;
}

.tech-scouting-table th:nth-child(2),
.tech-scouting-table td:nth-child(2) {
  width: 70%;
}

.tech-scouting-table th:nth-child(3),
.tech-scouting-table td:nth-child(3) {
  width: 10%;  
  text-align: center;
}

.tech-scouting-table th:nth-child(4),
.tech-scouting-table td:nth-child(4) {
  width: 5%;  
  text-align: center;
}

.tech-scouting-table th {
  font-weight: bold;
  color: #666;
}

/* Dark mode styles */
.tech-scouting-page.dark {
  color: #fff;
  background-color: #1a1a1a;
}

.tech-scouting-page.dark .tech-scouting-table th {
  color: #ccc;
}

.tech-scouting-page.dark .tech-scouting-table td,
.tech-scouting-page.dark .tech-scouting-table th {
  border-bottom-color: #333;
}

.tech-scouting-page.dark .tech-scouting-underline {
  border-bottom-color: #555;
}

/* Company cell styles */
.company-name-cell {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
}

.company-button {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  font: inherit;
  color: inherit;
  cursor: pointer;
  text-align: left;
  width: 100%;
  white-space: normal;
}

.company-button:hover {
  text-decoration: underline;
}

.company-button.dark {
  color: rgb(63, 189, 230);
  font-weight: bold;
}

.company-button.dark:hover {
  color: #333333;
  font-weight: bold;
}

/* Company description cell */
.company-description-cell {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5;
  max-height: calc(1.5em * 4);
}

/* Source cell */
.source-cell {
  text-align: center;
  font-weight: 500;
  color: white; /* Default color for all sources */
}

/* Results header layout */
.tech-scouting-results-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
  position: relative;
}

/* Search container and wrapper */
.tech-scouting-search-container {
  display: flex;
  align-items: center;
  margin-top: 20px; /* Move the search bar slightly down */
}

.tech-scouting-search-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  width: 450px;
}

.tech-scouting-search-controls {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  z-index: 2;
}

.tech-scouting-results-count.dark {
  color: #ddd;
}

.tech-scouting-results-count.light {
  color: #333;
}

/* Search bar styling */
.tech-scouting-search-input {
  padding: 8px 15px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 20px;
  width: 100%;
  transition: all 0.3s ease;
}

.tech-scouting-search-input:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

.tech-scouting-search-input.dark {
  background-color: #8888881a;
  color: #fff;
  border: 1px solid #555;
}

.tech-scouting-search-input.light {
  background-color: #fff;
  color: #333;
}

.tech-scouting-search-clear {
  position: relative;
  background: none;
  border: none;
  color: #999;
  cursor: pointer;
  font-size: 14px;
  padding: 0 8px;
  margin-right: 5px;
  transition: color 0.3s ease;
}

.tech-scouting-search-clear:hover {
  color: #333;
}

.tech-scouting-page.dark .tech-scouting-search-clear:hover {
  color: #fff;
}

/* Search toggle styles */
.tech-scouting-search-toggle-container {
  display: flex;
  align-items: center;
  background: transparent;
  border-radius: 15px;
  padding: 2px 5px;
  margin-left: 5px;
}

.tech-scouting-search-toggle-button {
  background-color: transparent;
  color: inherit;
  border: none;
  font-size: 0.8rem;
  padding: 2px 4px;
  cursor: pointer;
  transition: color 0.3s ease;
}

.tech-scouting-search-toggle-button.active {
  font-weight: bold;
}

.tech-scouting-search-toggle-button:hover {
  color: #2ba9d2;
}

.tech-scouting-page.dark .tech-scouting-search-toggle-button {
  color: #fff;
}

.tech-scouting-page.light .tech-scouting-search-toggle-button {
  color: #333;
}

/* Separator between toggle buttons */
.tech-scouting-search-toggle-separator {
  margin: 0 2px;
  color: inherit;
  font-size: 0.85rem;
}


/* Dark and Light Mode Styles */
.tech-scouting-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  margin-top: 80px;
}

.tech-scouting-container.dark {
  background-color: #191A1A; /* Dark background */
  color: #ffffff; /* White text color */
}

.tech-scouting-container.light {
  background-color: #f4f4f4; /* Light background */
  color: #333333; /* Dark text color */
}

.tech-scouting-container h1 {
  text-align: center;
  height: 60px;
  line-height: 60px;
  margin: 0;
}

.tech-scouting-container h1.dark {
  color: #ffffff;
}

.tech-scouting-container h1.light {
  color: #333333;
}

.tech-scouting-container p {
  text-align: center;
  width: 90%;
  margin: 10px auto 20px auto;
  font-size: 1rem;
}

.tech-scouting-container p.dark {
  color: #ffffff;
}

.tech-scouting-container p.light {
  color: #333333;
}


.tech-scouting-query-input {
  width: 280px;  
  max-width: 100%;
  font-size: 28px;
  background: none;
  border: none;
  outline: none;
  padding: 8px 0;
  transition: all 0.3s ease;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border: 2px solid transparent;
  gap: 12px;
}

.tech-scouting-query-input:focus {
  border-color: #2ba9d2;
  border-radius: 4px;
  padding: 8px;
}

.tech-scouting-query-input.dark {
  color: #ffffff;
}

.tech-scouting-query-input.light {
  color: #333333;
}

.tech-scouting-query-input::placeholder {
  color: #888;
}

/* Buttons Container */
.tech-scouting-buttons-container {
  position: absolute;
  bottom: 10px;
  left: 10px;
  right: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

/* Button Wrapper */
.tech-scouting-button-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 10px;
}

/* Precision and Library Dropdown Buttons */
.tech-scouting-dropdown-button,
.tech-scouting-library-dropdown-button {
  padding: 8px;
  border: none;
  border-radius: 0.375rem;
  background-color: transparent;
  cursor: pointer;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;
}

.tech-scouting-dropdown-button.dark,
.tech-scouting-library-dropdown-button.dark {
  color: #ffffff;
}

.tech-scouting-dropdown-button.light,
.tech-scouting-library-dropdown-button.light {
  color: #333333;
}

.tech-scouting-dropdown-button:hover,
.tech-scouting-dropdown-button:focus,
.tech-scouting-library-dropdown-button:hover,
.tech-scouting-library-dropdown-button:focus {
  background-color: #444;
  border-radius: 0.375rem;
}

/* Submit Button */
.tech-scouting-submit-button {
  margin-left: auto;
  padding: 5px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  font-size: 12px;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 200px; 
}

.tech-scouting-submit-button.dark {
  background-color: rgb(63, 189, 230);
  color: #1e1e1e;
}

.tech-scouting-submit-button.light {
  background-color: #ddd;
  color: #333333;
}

.tech-scouting-submit-button:hover {
  background-color: #2ba9d2;
}

/* Dropdown Menus */
.tech-scouting-dropdown-menu,
.tech-scouting-library-dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  border: 1px solid #444;
  border-radius: 0.375rem;
  z-index: 1000;
  font-size: 16px;
  margin-top: 5px;
  max-height: 200px;
  overflow-y: auto;
  display: none; /* Hidden by default */
}

/* Show dropdown when 'open' class is present */
.tech-scouting-dropdown-menu.open,
.tech-scouting-library-dropdown-menu.open {
  display: block;
}

.tech-scouting-dropdown-menu.dark {
  background-color: #333;
  color: #ffffff;
}

.tech-scouting-dropdown-menu.light {
  background-color: #f0f0f0;
  color: #333333;
}

.tech-scouting-library-dropdown-menu.dark {
  background-color: #333;
  color: #ffffff;
}

.tech-scouting-library-dropdown-menu.light {
  background-color: #f0f0f0;
  color: #333333;
}

/* Dropdown Items */
.tech-scouting-dropdown-item {
  padding: 12px;
  cursor: pointer;
}

.tech-scouting-dropdown-item.dark:hover,
.tech-scouting-dropdown-item.dark:focus {
  background-color: rgb(63, 189, 230);
  color: #202222;
}

.tech-scouting-dropdown-item.light:hover,
.tech-scouting-dropdown-item.light:focus {
  background-color: #ddd;
  color: #333333;
}

.tech-scouting-tooltip-text::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

/* Error Styling */
.tech-scouting-error {
  margin-top: 10px;
  text-align: center;
}

.tech-scouting-error.dark {
  color: white;
}

.tech-scouting-error.light {
  color: #333333;
}

/* Response Styling */
.tech-scouting-response {
  margin-top: 20px;
  text-align: left;
}

.tech-scouting-response.dark {
  color: #ffffff;
}

.tech-scouting-response.light {
  color: #333333;
}


.tech-scouting-results-header h2.dark {
  color: #ffffff;
}

.tech-scouting-results-header h2.light {
  color: #333333;
}

.tech-scouting-search-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  width: 450px;
}

/* Generate Report Button */
.tech-scouting-generate-report {
  padding: 8px 16px;
  background-color: rgb(63, 189, 230);
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.tech-scouting-generate-report:hover {
  background-color: #333333;
}

.tech-scouting-generate-report.dark {
  background-color: rgb(63, 189, 230);
}

.tech-scouting-generate-report.dark:hover {
  background-color: #333333;
}

/* Result Checkbox */
.tech-scouting-result-checkbox {
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.tech-scouting-result-checkbox:checked {
  accent-color: rgb(63, 189, 230);
}

.dark .tech-scouting-result-checkbox:checked {
  accent-color: rgb(63, 189, 230);
}


/* Result Card */
.tech-scouting-result-card {
  border: 1px solid #444;
  border-radius: 0.375rem;
  padding: 20px;
  margin-bottom: 10px;
  text-align: left;
}

.tech-scouting-result-card.dark {
  background-color: #2c2c2c;
  color: #ffffff;
}

.tech-scouting-result-card.light {
  background-color: #fff;
  color: #333333;
}

.tech-scouting-result-card h3 {
  margin-top: 0;
}

.tech-scouting-result-card h3.dark {
  color: rgb(63, 189, 230);
}

.tech-scouting-result-card h3.light {
  color: #333333;
}

.tech-scouting-result-card p,
.tech-scouting-result-card div {
  margin: 0;
  line-height: 1.6;
  text-align: left;
}

/* Visually Hidden (for screen readers) */
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  white-space: nowrap;
}

/* Skip link styling */
.skip-link {
  position: absolute;
  top: -40px;
  left: 10px;
  background: #000;
  color: #fff;
  padding: 8px;
  z-index: 100;
  text-decoration: none;
  transition: top 0.3s ease;
}

.skip-link:focus {
  top: 10px;
}

/* Report Generated Header */
.tech-scouting-report-generated-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
}

.tech-scouting-report-generated-header.dark {
  color: #ffffff;
}

.tech-scouting-report-generated-header.light {
  color: #333333;
}

/* Download Button */
.tech-scouting-download-button {
  padding: 10px 20px;
  border: none;
  border-radius: 0.375rem;
  cursor: pointer;
  font-size: 16px;
}

.tech-scouting-download-button.dark {
  background-color: rgb(63, 189, 230);
  color: #1A1C1C;
}

.tech-scouting-download-button.light {
  background-color: #ddd;
  color: #333333;
}

.tech-scouting-download-button:hover {
  background-color: #2ba9d2;
}

/* Slider Styling */
.tech-scouting-slider-container {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: 0;
}

.tech-scouting-slider-label {
  font-size: 14px;
  color: #333;
}

.tech-scouting-slider-label.dark {
  color: #fff;
}

/* Switch styles */
.tech-scouting-switch {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 24px;
  align-items: center;
}

.tech-scouting-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.tech-scouting-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 24px;
}

.tech-scouting-slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .tech-scouting-slider {
  background-color: rgb(63, 189, 230);
}

input:checked + .tech-scouting-slider:before {
  transform: translateX(24px);
}

.dark input:checked + .tech-scouting-slider {
  background-color: rgb(63, 189, 230);
}

/* Disabled state */
.tech-scouting-switch input:disabled + .tech-scouting-slider {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Action button styles */
.action-button {
  padding: 6px 12px;
  border-radius: 4px;
  border: 1px solid #ddd;
  background-color: transparent;
  cursor: pointer;
  transition: all 0.2s ease;
}

.action-button:hover {
  background-color: #f5f5f5;
}

.action-button.dark {
  color: #fff;
  border-color: #555;
}

.action-button.dark:hover {
  background-color: #333;
}

/* Error message styles */
.error-message {
  color: #dc3545;
  padding: 10px;
  margin: 10px 0;
  border-radius: 4px;
  background-color: rgba(220, 53, 69, 0.1);
}

/* Globe icon styling */
.globe-icon {
  filter: brightness(0) invert(1);
}

/* Responsive styles */
@media (max-width: 768px) {
  .tech-scouting-page {
    padding: 0 10px;
  }
  
  .tech-scouting-table th,
  .tech-scouting-table td {
    padding: 8px;
  }
}
/* Responsive adjustments */
@media (max-width: 480px) {
  .tech-scouting-page {
    padding: 10px 5px;
  }
  
  .tech-scouting-title {
    font-size: 1.5rem;
  }
  .tech-scouting-buttons-container {
    position: absolute;
    top: 115px;
    left: 5px;
    right: 5px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 14px;
  }

  .tech-scouting-container h1 {
    height: auto;
    line-height: normal;
    padding: 10px 0;
  }

  .tech-scouting-button-wrapper {
    margin-right: 10px;
  }

  .tech-scouting-dropdown-button,
  .tech-scouting-library-dropdown-button {
    position: relative;
    bottom: 15px;
    left: 0;
  }

  .tech-scouting-submit-button {
    position: relative;
    bottom: 7px;
    right: 0;
    height: 20px;
    width: 20px;
    font-size: 12px;
  }
  
  .tech-scouting-slider-container {
    display: none;
  }
  
  /* Adjust tooltip max-width for smaller screens */
  .tech-scouting-tooltip-text {
    max-width: 90vw;
  }
}

h2.tech-scouting-results-count {
  position: relative;
  top: 10px; /* Move just the results count down */
}
