/* CompanyDetails.css */

.company-details-container {
    width: 100%;
    max-width: none;
    margin: 0;
    padding: 20px;
    box-shadow: none;
}
  
.company-details-container.dark {
    background-color: #191A1A; /* Dark background */
    color: #ffffff; /* White text color */
}
  
.company-details-container.light {
    background-color: #f4f4f4; /* Light background */
    color: #333333; /* Dark text color */
}

/* Header styles */
.company-header {
    display: flex;
    align-items: flex-start;
    padding: 10px 0 20px 0;
    margin-bottom: 20px;
    border-bottom: 1px solid rgba(128, 128, 128, 0.2);
    width: 100%;
    justify-content: space-between; /* This will push items to the edges */
}

/* Add styles for the right side of the header */
.header-right {
    margin-left: auto; /* Push to the right */
    display: flex;
    align-items: center;
}

.company-logo {
    width: 50px;
    height: 50px;
    border-radius: 6px;
    object-fit: cover;
    margin-right: 15px;
    background-color: #e0e0e0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    color: #666;
    flex-shrink: 0;
}

.company-logo.dark {
    background-color: #2c2d2e;
    color: #e0e0e0;
}

.company-header-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex: 1;
}

.company-title-row {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    gap: 15px;
}

.company-name {
    font-size: 1.8rem;
    font-weight: bold;
    margin: 0;
    padding: 0;
    color: #333333;
}

.company-details-container.dark .company-name {
    color: rgb(63, 189, 230);
}

.company-source, .data-flow-indicator, .company-location {
    font-size: 0.9rem;
    margin: 0;
    color: #666;
    white-space: nowrap;
}

.company-source.dark, .data-flow-indicator.dark, .company-location.dark {
    color: #aaa;
}

.location-icon {
    display: inline-block;
    margin-right: 4px;
    vertical-align: middle;
    fill: #333333;
}

.company-details-container.dark .location-icon {
    fill: #ffffff;
}

.company-details-container h1 {
    margin-bottom: 30px;
    font-size: 2rem;
    padding-bottom: 10px;
}

.company-details-container h1.dark {
    color: rgb(63, 189, 230);
}

.company-details-container h1.light {
    color: #333333;
}
  
.company-details-container p {
    margin-bottom: 20px;
    line-height: 1.6;
}
  
.company-details-container p strong {
    display: inline-block;
    width: 150px;
}
  
.company-details-container a {
    color: rgb(63, 189, 230);
    text-decoration: none;
}
  
.company-details-container a:hover {
    text-decoration: underline;
}
  
/* Back Button Styling */
.company-details-back-button {
    display: inline-block;
    padding: 10px 20px;
    border: none;
    border-radius: 0.375rem;
    cursor: pointer;
    font-size: 16px;
    text-align: center;
}
  
.company-details-back-button.dark {
    background-color: rgb(63, 189, 230);
    color: #1A1C1C;
}
  
.company-details-back-button.light {
    background-color: #ddd;
    color: #333333;
}
  
.company-details-back-button:hover {
    background-color: #2ba9d2;
}

/* Save button styles */
.company-details-save-button {
    display: inline-block;
    padding: 10px 20px;
    border: none;
    border-radius: 0.375rem;
    cursor: pointer;
    font-size: 16px;
    text-align: center;
    margin-right: 10px; /* Add margin to separate from the Go Back button */
}
  
.company-details-save-button.dark {
    background-color: rgb(63, 189, 230);
    color: #1A1C1C;
}
  
.company-details-save-button.light {
    background-color: #ddd;
    color: #333333;
}
  
.company-details-save-button:hover {
    background-color: #2ba9d2;
}

.company-details-save-button:disabled {
    opacity: 0.8;
    cursor: default;
    background-color: #4CAF50; /* Green color for saved state */
    color: white;
}

.company-details-save-button:disabled:hover {
    background-color: #4CAF50; /* Keep the same color on hover when disabled */
}

/* Backend data display */
.backend-data {
    margin-top: 30px;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #f9f9f9;
}

.backend-data.dark {
    border-color: #444;
    background-color: #2a2a2a;
}

.backend-data h2 {
    margin-top: 0;
}

.backend-data pre {
    overflow-x: auto;
    padding: 10px;
    background-color: #f0f0f0;
    border-radius: 4px;
    font-size: 0.9rem;
}

.backend-data.dark pre {
    background-color: #333;
}

/* Company Tags */
.company-tags-section {
  margin-top: 20px;
}

.company-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 5px;
}

.company-tag {
    display: inline-block;
    padding: 4px 10px;
    border-radius: 16px;
    font-size: 0.75rem;
    background-color: #e0e0e0;
    color: #555;
}

.company-tag.dark {
    background-color: #333;
    color: #ddd;
}

.company-tag.product-type {
  background-color: #e3f2fd;
  color: #0d47a1;
}

.company-tag.technology-type {
  background-color: #e8f5e9;
  color: #1b5e20;
}

.company-tag.market-vertical {
  background-color: #fff3e0;
  color: #e65100;
}

.company-tag.customer-type {
  background-color: #f3e5f5;
  color: #4a148c;
}

.company-details-container.dark .company-tag {
  background-color: #333;
  color: #f0f0f0;
}

.company-details-container.dark .company-tag.product-type {
  background-color: #0d47a1;
  color: #e3f2fd;
}

.company-details-container.dark .company-tag.technology-type {
  background-color: #1b5e20;
  color: #e8f5e9;
}

.company-details-container.dark .company-tag.market-vertical {
  background-color: #e65100;
  color: #fff3e0;
}

.company-details-container.dark .company-tag.customer-type {
  background-color: #4a148c;
  color: #f3e5f5;
}

/* SBIR Data Section */
.sbir-data-section {
  margin-top: 40px;
  padding-top: 20px;
}

.sbir-data-section h2 {
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #333;
  display: inline-block;
  position: relative;
  padding-bottom: 6px;
}

.sbir-data-section h2::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 370%; /* Extend past the text */
  height: 1px;
  background-color: currentColor;
}

.company-details-container.dark .sbir-data-section h2 {
  color: #fff;
}

.sbir-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  justify-content: space-between;
  margin-top: 15px;
}

.sbir-card {
  flex: 1;
  min-width: 160px;
  max-width: 24%;
  background-color: #f8f8f8;
  border-radius: 6px;
  padding: 12px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.sbir-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
}

.company-details-container.dark .sbir-card {
  background-color: #2c2d2e;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.sbir-card h3 {
  font-size: 0.9rem;
  margin-top: 0;
  margin-bottom: 10px;
  color: #555;
  font-weight: 600;
}

.company-details-container.dark .sbir-card h3 {
  color: #aaa;
}

.sbir-value {
  font-size: 1.3rem;
  font-weight: bold;
  margin: 0;
  color: #333;
}

.company-details-container.dark .sbir-value {
  color: #fff;
}

.sbir-subvalue {
  font-size: 0.9rem;
  color: #666;
  margin: 5px 0 0 0;
}

.company-details-container.dark .sbir-subvalue {
  color: #aaa;
}

/* SBIR Table Section */
.sbir-table-section {
  margin-top: 40px;
  padding-top: 10px;
}

.sbir-table-container {
  width: 100%;
  overflow-x: auto;
  margin-top: 0;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08);
}

.company-details-container.dark .sbir-table-container {
  background-color: #2a2a2a;
}

.sbir-awards-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 0.9rem;
}

.sbir-awards-table th {
  background-color: #f5f5f5;
  color: #333;
  text-align: left;
  padding: 12px 15px;
  font-weight: 600;
  border-bottom: 1px solid #ddd;
}

.company-details-container.dark .sbir-awards-table th {
  background-color: #333;
  color: #eee;
  border-bottom: 1px solid #444;
}

.sbir-awards-table td {
  padding: 10px 15px;
  border-bottom: 1px solid #eee;
}

.company-details-container.dark .sbir-awards-table td {
  border-bottom: 1px solid #333;
  color: #ddd;
}

.sbir-awards-table tr:last-child td {
  border-bottom: none;
}

.sbir-awards-table tr:hover {
  background-color: #f9f9f9;
}

.company-details-container.dark .sbir-awards-table tr:hover {
  background-color: #333;
}

/* SBIR Chart Section */
.sbir-chart-section {
  margin-top: 40px;
  padding-top: 10px;
}

.sbir-chart-container {
  height: 400px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.03);
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.company-details-container.dark .sbir-chart-container {
  background-color: rgba(0, 0, 0, 0.2);
}

@media (max-width: 600px) {
    .company-details-container {
        padding: 15px;
        margin: 20px auto;
    }
  
    .company-header {
        flex-direction: column;
    }
    
    .company-logo {
        margin-bottom: 10px;
    }
    
    .header-right {
        margin-left: 0;
        margin-top: 15px;
        width: 100%;
    }
    
    .company-details-back-button {
        width: 100%;
    }
    
    .company-title-row {
        flex-direction: column;
        align-items: flex-start;
        gap: 5px;
    }
    
    .company-details-container h1 {
        font-size: 1.5rem;
    }
  
    .company-details-container p strong {
        width: 120px;
    }
}

@media (max-width: 900px) {
  .sbir-cards {
    flex-direction: column;
  }
  
  .sbir-card {
    width: 100%;
  }
}