/* StakeholderDetails.css */

.stakeholder-details-container {
  max-width: 800px;
  margin: 80px auto 20px auto;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.stakeholder-details-container.dark {
  background-color: #191A1A;
  color: #ffffff;
}

.stakeholder-details-container.light {
  background-color: #f4f4f4;
  color: #333333;
}

.stakeholder-details-container h1 {
  margin-bottom: 30px;
  font-size: 2rem;
  padding-bottom: 10px;
  border-bottom: 2px solid;
}

.stakeholder-details-container h1.dark {
  color: rgb(63, 189, 230);
  border-bottom-color: rgb(63, 189, 230);
}

.stakeholder-details-container h1.light {
  color: #333333;
  border-bottom-color: #333333;
}

.stakeholder-details-container p {
  margin-bottom: 20px;
  line-height: 1.6;
}

.stakeholder-details-container p strong {
  display: inline-block;
  width: 150px;
}

.stakeholder-details-container a {
  color: rgb(63, 189, 230);
  text-decoration: none;
}

.stakeholder-details-container a:hover {
  text-decoration: underline;
}

/* Back Button Styling */
.stakeholder-details-back-button {
  display: inline-block;
  margin-top: 30px;
  padding: 10px 20px;
  border: none;
  border-radius: 0.375rem;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
}

.stakeholder-details-back-button.dark {
  background-color: rgb(63, 189, 230);
  color: #1A1C1C;
}

.stakeholder-details-back-button.light {
  background-color: #ddd;
  color: #333333;
}

.stakeholder-details-back-button:hover {
  background-color: #2ba9d2;
}

@media (max-width: 600px) {
  .stakeholder-details-container {
    padding: 15px;
  }

  .stakeholder-details-container h1 {
    font-size: 1.5rem;
  }

  .stakeholder-details-container p strong {
    width: 100%;
    margin-bottom: 5px;
  }

  .stakeholder-details-back-button {
    width: 100%;
    margin-top: 20px;
  }
}
