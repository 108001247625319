/* Modal Overlay - darkens the background */
.prompt-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(2px);
}

/* Modal Container */
.prompt-modal-container {
  width: 90%;
  max-width: 600px;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);
}

.prompt-modal-container.light {
  background-color: #ffffff;
  color: #333333;
  border: 1px solid #e0e0e0;
}

.prompt-modal-container.dark {
  background-color: #1A1C1C;
  color: #ffffff;
  border: 1px solid #333333;
}

/* Modal Header */
.prompt-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px;
  border-bottom: 1px solid rgba(128, 128, 128, 0.2);
}

.prompt-modal-header h2 {
  margin: 0;
  font-size: 1.25rem;
  font-weight: 600;
}

.prompt-modal-close-button {
  background: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.prompt-modal-container.light .prompt-modal-close-button {
  color: #333333;
}

.prompt-modal-container.dark .prompt-modal-close-button {
  color: #ffffff;
}

.prompt-modal-close-button:hover {
  background-color: rgba(128, 128, 128, 0.2);
}

/* Modal Body */
.prompt-modal-body {
  padding: 20px;
  flex-grow: 1;
}

.prompt-modal-textarea {
  width: 100%;
  padding: 12px;
  border-radius: 4px;
  resize: vertical;
  font-family: inherit;
  font-size: 1rem;
  line-height: 1.5;
}

.prompt-modal-textarea.light {
  background-color: #ffffff;
  color: #333333;
  border: 1px solid #e0e0e0;
}

.prompt-modal-textarea.dark {
  background-color: #2A2C2C;
  color: #ffffff;
  border: 1px solid #444444;
}

.prompt-modal-textarea:focus {
  outline: none;
  border-color: rgb(63, 189, 230);
  box-shadow: 0 0 0 2px rgba(63, 189, 230, 0.2);
}

/* Modal Footer */
.prompt-modal-footer {
  display: flex;
  justify-content: flex-end;
  padding: 16px 20px;
  border-top: 1px solid rgba(128, 128, 128, 0.2);
  gap: 12px;
}

/* Buttons */
.prompt-modal-cancel-button,
.prompt-modal-submit-button {
  padding: 8px 16px;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  border: none;
  transition: background-color 0.2s;
}

.prompt-modal-cancel-button.light {
  background-color: #f0f0f0;
  color: #333333;
}

.prompt-modal-cancel-button.dark {
  background-color: #333333;
  color: #ffffff;
}

.prompt-modal-cancel-button:hover {
  background-color: #e0e0e0;
}

.prompt-modal-submit-button {
  background-color: rgb(63, 189, 230);
  color: black;
}

.prompt-modal-submit-button:hover {
  background-color: #2ba9d2;
}

.prompt-modal-submit-button:disabled {
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
}

/* Accessibility */
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
